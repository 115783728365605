import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        meta: {
            title: 'index.navTitle',
            keywords: 'index.navKeywords',
            description: 'index.navDescription',
            keepAlive: true
        },
        component: Home
    },
    // {
    //     path: '/iframe',
    //     meta: {
    //         nameEn: '关于我们',
    //     },
    //     name: 'About',
    //     component: () => import( /* webpackChunkName: "about" */ '@/views/Iframe.vue')
    // },
    // {
    //     path: '/about',
    //     meta: {
    //         nameEn: '关于我们',
    //     },
    //     name: 'About',
    //     component: () => import( /* webpackChunkName: "about" */ '@/views/About.vue')
    // },
    {
        path: '/Feedback',
        name: 'Feedback',
        meta: {
            nameEn: '意见反馈'
        },

        component: () => import( /* webpackChunkName: "about" */ '@/components/Feedback.vue')
    },
    {
        path: '/member',
        name: 'member',
        meta: {
            requireAuth: true,
            nameEn: '会员付费',
        },

        component: () => import( /* webpackChunkName: "about" */ '@/views/member.vue')
    },
    // {
    //     path: '/Comment',
    //     name: 'Comment',
    //     meta: {
    //         nameEn: '评价',
    //     },
    //     component: () => import( /* webpackChunkName: "about" */ '@/views/FooterPage/Comment.vue')
    // },
    // {
    //     path: '/help',
    //     name: 'help',
    //     meta: {
    //         nameEn: '帮助',
    //     },
    //     component: () => import( /* webpackChunkName: "about" */ '@/views/FooterPage/help.vue')
    // },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     meta: {
    //         nameEn: "登录"
    //     },
    //     component: () => import('@/views/Login.vue')
    // },
    // {
    //     path: '/Register',
    //     name: 'Register',
    //     meta: {
    //         nameEn: "注册"
    //     },
    //     component: () => import('@/views/Register.vue')
    // },
    // {
    //     path: '/forgetPassword',
    //     name: 'forgetPassword',
    //     meta: {
    //         nameEn: "忘记密码"
    //     },
    //     component: () => import('@/views/forgetPassword.vue')
    // },
    // {
    //     path: '/basicInformation',
    //     name: 'BasicInformation',
    //     component: () => import('@/views/BasicInformation.vue'), //基本信息
    //     meta: {
    //         requireAuth: true,
    //         nameEn: '基本信息'
    //     },
    // },
    // {
    //     path: '/Science',
    //     name: 'Science',
    //     component: () => import('@/views/Science.vue'), //基本信息
    // },
    {
        path: '/technology',
        name: 'Technology',
        component: () => import('@/views/Science.vue'), //基本信息
        meta: {
            title: 'technology.navTitle',
            keywords: 'technology.navKeywords',
            description: 'technology.navDescription'
        }
    },
    {
        path: '/technology/:id',
        name: 'TechnologyDetail',
        component: () => import('@/views/PrimaryClassfy/articalDetail.vue'),
        meta: {
            mark: 'technology',
            title: 'technologyDetail.navTitle',
            keywords: 'technologyDetail.navKeywords',
            keepAlive: true
        }
    },
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/Science.vue'), //基本信息
        meta: {
            mark: 'news',
            title: 'news.navTitle',
            keywords: 'news.navKeywords',
            description: 'news.navDescription'
        }
    },
    {
        path: '/news/:id',
        name: 'NewsDetail',
        component: () => import('@/views/PrimaryClassfy/articalDetail.vue'),
        meta: {
            mark: 'news',
            title: 'newsDetail.navTitle',
            keywords: 'newsDetail.navKeywords',
            keepAlive: true
        }
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import('@/views/Science.vue'), //基本信息
        meta: {
            mark: 'report',
            title: 'report.navTitle',
            keywords: 'report.navKeywords',
            description: 'report.navDescription',
            keepAlive: true
        }
    },
    {
        path: '/report/:parentId/:cateId',
        name: 'ReportList',
        component: () => import('@/views/PrimaryClassfy/PaperList.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/report/:id',
        name: 'ReportDetail',
        component: () => import('@/views/PrimaryClassfy/PaperDetail.vue'),
        meta: {
            mark: 'report',
            title: 'reportDetail.navTitle',
            keywords: 'reportDetail.navKeywords',
            keepAlive: true
        }
    },
    {
        path: '/opportunity',
        name: 'Opportunity',
        component: () => import('@/views/Science.vue'), //基本信息
        meta: {
            mark: 'opportunity',
            title: 'opportunity.navTitle',
            keywords: 'opportunity.navKeywords',
            description: 'opportunity.navDescription',
            // keepAlive: true
        }
    },
    {
        path: '/opportunity/:id',
        name: 'OpportunityDetail',
        component: () => import('@/views/PrimaryClassfy/CommercialDetail.vue'),
        meta: {
            mark: 'opportunity',
            title: 'opportunityDetail.navTitle',
            keywords: 'opportunityDetail.navKeywords',
            keepAlive: true
        }
    },
    // {
    //   path: '/Science/cd ',
    //   name: 'NewsList',
    //   component: () => import('@/views/PrimaryClassfy/NewsList.vue')
    // },
    // {
    //     path: '/pdfDetail',
    //     name: 'pdfDetail',
    //     component: () => import('@/views/PrimaryClassfy/pdfDetail.vue'),
    //     meta: {
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/articalDetail',
    //     name: 'articalDetail',
    //     component: () => import('@/views/PrimaryClassfy/articalDetail.vue')
    // },
    // {
    //     path: '/DataService',
    //     name: 'DataService',
    //     component: () => import('@/views/PrimaryClassfy/DataService.vue')
    // },
    {
        path: '/AIAnswer',
        name: 'AIAnswer',
        component: () => import('@/views/PrimaryClassfy/AIAnswer.vue'),
        meta: {
            mark: 'ai',
            title: 'ai.navTitle',
            keywords: 'ai.navKeywords',
            description: 'ai.navDescription',
            keepAlive: true
        }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/component/test.vue'),
        meta: {
            mark: 'ai',
            title: 'ai.navTitle',
            keywords: 'ai.navKeywords',
            description: 'ai.navDescription',
            keepAlive: true
        }
    },

    {
        path: '/AIPop',
        name: 'AIPop',
        component: () => import('@/views/AiPop.vue'),
        meta: {
            mark: 'ai',
            title: 'ai.navTitle',
            keywords: 'ai.navKeywords',
            description: 'ai.navDescription',
            keepAlive: true
        }
    },
    {
        path: '/search/:keyword?',
        name: 'Search',
        component: () => import('@/views/PrimaryClassfy/Search.vue'),
        meta: {
            keepAlive: false,
            // nameEn: '搜索',
        },
    },
    // {
    //     path: '/message',
    //     name: 'Message',
    //     component: () => import('@/views/Message.vue'),
    //     meta: {
    //         requireAuth: true,
    //         nameEn: '信息'
    //     },
    //     children: [{
    //         path: '/message',
    //         name: 'SystemMessages',
    //         component: () => import('@/views/SystemMessages.vue'), // 系统消息
    //         meta: {
    //             requireAuth: true,
    //             nameEn: '系统消息'
    //         },
    //     }, {
    //         path: '/subscriptionTheme',
    //         name: 'SubscriptionTheme',
    //         component: () => import('@/views/SubscriptionTheme.vue'), // 订阅主题
    //         meta: {
    //             requireAuth: true,
    //             nameEn: ' 订阅消息'   //订阅主题
    //         },
    //     }, {
    //         path: '/receivedlikes',
    //         name: 'Receivedlikes',
    //         component: () => import('@/views/Receivedlikes.vue'), // 收到的赞
    //         meta: {
    //             requireAuth: true,
    //             nameEn: '收到的赞'
    //         },
    //     }, {
    //         path: '/replyToMy',
    //         name: 'ReplyToMy',
    //         component: () => import('@/views/ReplyToMy.vue'), // 回复我的
    //         meta: {
    //             requireAuth: true,
    //             nameEn: '回复我的'
    //         },
    //     }, ]
    // },
    {
        path: '/messageDetail',
        name: 'MessageDetail',
        component: () => import('@/views/MessageDetail.vue'), //系统消息详情
        meta: {
            requireAuth: true,
            nameEn: '系统消息详情',
        },
    },
    // {
    //     path: '/mySubscriptions',
    //     name: 'MySubscriptions',
    //     meta: {
    //         nameEn: '我的订阅',
    //     },

    //     component: () => import('@/views/MySubscriptions.vue'), // 我的订阅
    // },
    // {
    //     path: '/Info1',
    //     name: 'Info1',
    //     component: () => import('@/views/userInfo/Info.vue'), // 个人信息
    //     meta: {
    //         requireAuth: true,
    //         needRefresh: true, // 需要刷新
    //         nameEn: ' 个人信息',
    //     },
    // },
    // {
    //     path: '/secure1',
    //     name: 'Secure1',
    //     component: () => import('@/views/userInfo/Secure.vue'), // 账户安全
    //     meta: {
    //         requireAuth: true,
    //         needRefresh: true, // 需要刷新
    //         nameEn: '账户安全',
    //     },
    // },
    // {
    //     path: '/changePhone1',
    //     name: 'ChangePhone11',
    //     component: () => import('@/views/userInfo/ChangePhone.vue'), // 修改手机号
    //     meta: {
    //         requireAuth: true,
    //         nameEn: '修改手机号',
    //     },
    // },
    // {
    //     path: '/changePassword1',
    //     name: 'ChangePassword1',
    //     component: () => import('@/views/userInfo/ChangePassword.vue'), // 修改密码
    //     meta: {
    //         requireAuth: true,
    //         nameEn: '修改密码',
    //     },
    // },
    // {
    //     path: '/changeEmail1',
    //     name: 'ChangeEmail1',
    //     component: () => import('@/views/userInfo/ChangeEmail.vue'), // 修改密码
    //     meta: {
    //         requireAuth: true,
    //         nameEn: '修改密码',
    //     },
    // },
    // {
    //     path: '/information1',
    //     name: 'Information1',
    //     component: () => import('@/views/userInfo/Information.vue'), // 资讯管理
    //     meta: {
    //         requireAuth: true,
    //         nameEn: ' 资讯管理'
    //     },
    // },
    // {
    //     path: '/topicSubscription1',
    //     name: 'TopicSubscription1',
    //     meta: {
    //         nameEn: '主题订阅',
    //         requireAuth: true,
    //     },
    //     component: () => import('@/views/userInfo/TopicSubscription.vue'), // 主题订阅

    // }, {
    //     path: '/orderList1',
    //     name: 'OrderList1',
    //     component: () => import('@/views/userInfo/OrderMangement.vue'), //订单列表
    //     meta: {
    //         requireAuth: true,
    //         nameEn: '订单列表'
    //     },
    // },
    {
        path: '/user',
        name: 'User',
        component: () => import('@/views/User.vue'),
        meta: {
            requireAuth: true,
            nameEn: ' 个人信息',
        },
        children: [
            {
                path: '/Info',
                name: 'Info',
                component: () => import('@/views/Info.vue'), // 个人信息
                meta: {
                    requireAuth: true,
                    needRefresh: true, // 需要刷新
                    title: 'user.info',
                },
            },
            {
                path: '/secure',
                name: 'Secure',
                component: () => import('@/views/Secure.vue'), // 账户安全
                meta: {
                    requireAuth: true,
                    needRefresh: true, // 需要刷新
                    // nameEn: '账户安全',
                    title: 'user.secure'
                },
            }, {
                path: '/changePhone',
                name: 'ChangePhone',
                component: () => import('@/views/ChangePhone.vue'), // 修改手机号
                meta: {
                    requireAuth: true,
                    // nameEn: '修改手机号',
                },
            }, {
                path: '/changePassword',
                name: 'ChangePassword',
                component: () => import('@/views/ChangePassword.vue'), // 修改密码
                meta: {
                    requireAuth: true,
                    // nameEn: '修改密码',
                    title: 'user.modifyPwd'
                },
            }, {
                path: '/changeEmail',
                name: 'ChangeEmail',
                component: () => import('@/views/ChangeEmail.vue'), // 修改密码
                meta: {
                    requireAuth: true,
                    // nameEn: '修改密码',
                },
            },
            // {
            //     path: '/information',
            //     name: 'Information',
            //     component: () => import('@/views/Information.vue'), // 资讯管理
            //     meta: {
            //         requireAuth: true,
            //         nameEn: ' 资讯管理'
            //     },
            // },
            {
                path: '/collection',
                name: 'collection',
                meta: {
                    // nameEn: '我的收藏',
                    requireAuth: true,
                    keepAlive: false,
                    title: 'user.myCollection',
                },
                component: () => import('@/views/MyCollection.vue'), // 主题订阅

            },
            {
                path: '/orderList',
                name: 'OrderList',
                component: () => import('@/views/OrderList.vue'), //订单列表
                meta: {
                    requireAuth: true,
                    title: 'user.myOrders',
                    // nameEn: '订单列表'
                },
            },
            {
                path: '/message',
                name: 'SystemMessages',
                component: () => import('@/views/SystemMessages.vue'), // 系统消息
                meta: {
                    requireAuth: true,
                    title: 'user.message',
                    // nameEn: '系统消息'
                },
            },
        ]
    },
    {
        path: '/wechatCallback',
        name: 'WechatCallback',
        meta: {
            nameEn: '微信绑定'
        },
        component: () => import('@/views/WechatCallback.vue'), // 微信绑定账号
    },
    {
        path: '/success',
        name: 'Success',
        meta: {
            nameEn: '微信绑定成功'
        },
        component: () => import('@/views/Success.vue'), // 微信绑定提示
    },
    // {
    //     path: '/ordermangement',
    //     name: 'OrderMangement',
    //     meta: {
    //         nameEn: '我的订单'
    //     },
    //     component: () => import('@/views/OrderMangement.vue'), // 微信绑定提示
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// router.beforeEach((to, from, next) => {
//
//     console.log("---------------======================")
//     console.log(to)
//     console.log(from)
//     console.log(next)
//     if (localStorage.getItem("myclick")) {
//         localStorage.removeItem("myclick")
//     }
//     if (to.path) {
//         if (window._hmt) {
//             window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
//         }
//     }
//
//     localStorage.setItem('Title', '')
//     const storedLanguage = localStorage.getItem("language");
//
//     next()
//     // if (to.name === 'Home')
//     // {
//     //     if (!to.query.language){
//     //         console.log("五语言")
//     //         const langParam = {
//     //             language: storedLanguage || 'zh'
//     //         };
//     //         next({
//     //             path: to.path,
//     //             query: langParam
//     //         });
//     //     } else if (to.query.language) {
//     //         console.log("有语言")
//     //         next();
//     //     }
//     // } else {
//     //     console.log("jinjies ")
//     //     const langParam = {
//     //         language: storedLanguage || 'zh',
//     //         ...to.query
//     //     };
//     //     next({
//     //         path: to.path,
//     //         query: langParam
//     //     });
//     // }
//     if (storedLanguage === 'en') {
//         document.title = "Oil and Gas knowledge-" + to.name
//     } else {
//         document.title = "油气知识-" + to.meta.nameEn;
//     }
//     if (to.query.id) {
//         const axiosInstance = Vue.prototype.$axios;
//         axiosInstance.get("/api/category/optionSelect", {
//             params: {
//                 level: 1,
//                 categoryId: to.query.id,
//             },
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: localStorage.getItem("access_token"),
//             },
//         })
//             .then((res) => {
//                 if (storedLanguage === 'en') {
//                     document.title = "Oil and Gas knowledge-" + res.data.data[0].titleEn
//                 } else {
//                     document.title = "油气知识-" + res.data.data[0].title;
//                 }
//
//             })
//             .catch((error) => {
//                 console.log(error);
//                 if (storedLanguage === 'en') {
//                     document.title = "Oil and Gas knowledge-" + 'Reports'
//                 } else {
//                     document.title = "油气知识-" + '行业报告';
//                 }
//             });
//
//     }
//
//
// });


export default router
