<template>
    <div>
      <!-- v-click-outside="handleBlur" -->
      <div class="AiPop" :class="{ 'm-aipop': isMobile === true }"  >
        <!-- <div class="buttonPop" @click="AiPopShow = !AiPopShow" v-show="!AiPopShow">
          <img src="@/assets/ai.png" />
        </div> -->
        <div class="pop" v-show="AiPopShow">
          <div class="box">
            <div class="content">
              <div class="AiHeader">
                <div class="AiHeader-img">
                  <span class="circle circle_r">
                    <img src="@/assets/ai.png" alt="" />
                  </span>
                  <p>{{ $t('aiDialog.aiName') }}</p>
                </div>
                <!-- <div class="close el-icon-close" @click="close()"></div> -->
              </div>
  
              <template v-if="!news_id">
                <p class="AiTitle chat-box">{{ $t('aiDialog.aiTip1')}}：</p>
                <div class="ai-tip chat-box">
                  {{ $t('aiDialog.aiTip2') }}
                  <span  @click="loginStart()">{{ $t('ai.membershipBtn') }}</span>
                </div>
                <div class="ai-tip chat-box">
                  {{ $t('aiDialog.aiTip3') }}
                  <span  @click="getMemberById()">{{ $t('ai.customizedServiceBtn') }}</span>
                </div>
              </template>
              <template v-else>
                <p class="AiTitle chat-box">{{ $t('aiDialog.serviceTip1') }}</p>
                <div class="ai-tip chat-box abstract-chat" @click="Abstract($t('aiDialog.serviceTip2'))">{{ $t('aiDialog.serviceTip2') }}</div>
                <div class="ai-tip chat-box abstract-chat" @click="Abstract($t('aiDialog.serviceTip3'))">{{ $t('aiDialog.serviceTip3') }}</div>
              </template>
  
              <div class="chat-list" id="content"  ref="content">
                <template v-for="(item, index) in info">
                  <div class="info_r info_default" v-if="item.type === 'leftinfo'" :key="index">
                    <span class="circle circle_r">
                      <img src="@/assets/ai.png" alt="" />
                    </span>
                    <div class="con_r con_text">
                      <div>{{ item.answer }}</div>
                      <div v-for="(item2, index) in item.question" :key="index">
                        <div class="con_que">
                          <div class="czkj-question-msg">
                            {{ item2.index }}
                            {{ item2.content }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="time_r">{{ item.time }}</div>
                  </div>
                  <div class="info_l" v-if="item.type === 'rightinfo'" :key="index">
                    <span class="circle circle_l">
                      <img
                        :src="item.athor?.avator ? baseURL + item.athor.avator : require('../assets/avator.png')"
                        alt="User Avatar"
                        style="border-radius: 45px"
                      />
                    </span>
                    <div class="con_l con_text">{{ item.content }}</div>
                    <div class="time_l">{{ item.time }}</div>
                  </div>
                </template>
              </div>
              <div class="chat-type-area">
                <div class="setproblem">
                  <textarea
                    maxlength="3000"
                    :disabled="isSendingMessage"
                    id="text"
                    :rows="1"
                    ref="inputArea"
                    @blur="blurHandle"
                    v-model="customerText"
                    @keydown.enter.prevent
                    @keydown.enter="($i18n.locale == 'en'? sentMsgEn : sentMsg)()"
                  >
                  </textarea>
                  <!-- <div class="sendTitle" v-show="hasPlaceholder" @click.prevent="hidePlaceholder()">
                    <p>
                      <span style="color: blue" @click.prevent="login" v-if="!userInfo.userId">{{ $t("common.login") }}</span>{{
                        $t(userInfo.userId ? "ai.aiInput" : "ai.aiInputLoginTip")
                      }}
                    </p>
                  </div> -->
                  <div class="send">
                    <div class="sendBox" @click.prevent="($i18n.locale == 'en'? sentMsgEn : sentMsg)()">
                      <p></p>
                    </div>
                  </div>          
                </div>
                <!-- 检查 countSum 和 countSum.data 是否存在 -->
                <!-- <div class="user-ai-sum" v-if="userInfo.userId">
                  <span>{{ $t('aiDialog.useNumTip1') }}</span>
                  <span style="color: red">
                    {{ countSum.data.freeNum + countSum.data.payNum }}
                  </span>
                  <span>{{ $t('aiDialog.useNumTip2') }}</span>
                  <span style="color: red">
                    {{ countSum.data.freeUse + countSum.data.payUse }}
                  </span>
                  <span>{{ $t('aiDialog.useNumTip3') }}</span>
                </div> -->
              </div>
              <el-dialog
                ref="myDialog1"
                :title="$t('ai.contactMethod')"
                :visible.sync="centerDialogVisible"
                width="300px"
                class="connectForm"
                :before-close="handleClose"
                :append-to-body="true"
                top="40vh"
              >
                <span style="margin: 5px"
                  >{{$t('ai.contact')}}：{{ contact.data.name }}</span
                ><br /><br />
                <span style="margin: 5px">{{$t('ai.phone')}}：{{ contact.data.phone }}</span
                ><br /><br />
                <span style="margin: 5px"
                  >{{$t('ai.email')}}：{{ contact.data.email }}</span
                >
                <span slot="footer" class="dialog-footer"></span>
              </el-dialog>
            </div>
            <!-- <div class="setproblem">
            <textarea
                placeholder="请输入需要咨询的问题"
                style=""
                :disabled="isSendingMessage"
                id="text"
                :rows="1"
                v-model="customerText"
                @keydown.enter="sentMsg()"
            ></textarea>
              <el-button @click="sentMsg()" class="buttonsend">
                <span style="vertical-align: 4px">发送</span>
              </el-button>
  
              <el-dialog
                  ref="myDialog"
                  title="温馨提示"
                  :visible.sync="kindtips"
                  width="450px"
                  :append-to-body='true'
                  class="connectForm"
                  :before-close="handleClose"
                  top="40vh"
              >
                <span style="margin:5px;">您的免费对话次数已用尽，成为付费用户拥有更多对话次数~</span><br><br>
                <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog2" size="mini">取消</el-button>
                    <el-button
                        size="mini"
                        style="background-color: #bd0022; border: none; margin: -3px 5px"
                        type="primary"
                        @click="loginStart()"
                    >
                      立即开通
                    </el-button>
                </span>
              </el-dialog>
            </div>
            <div>
              <div class="tips1">
                <div style="display: flex">
                  <div class="circle1" style="margin-top: 9px">
                  </div>
                  <span
                      style="font-size: 12px; margin-top: 8px;">AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数</span>
                  <div style="text-align: right;">
                    <el-button
                        size="mini"
                        style="background-color: #bd0022;border: none;margin:-3px 5px; margin-top: 2px;"
                        type="primary"
                        @click="loginStart()"
  
                    >
                      立即开通
                    </el-button>
                  </div>
  
                </div>
              </div>
              <div class="tips1">
                <div style="display: flex">
                  <div class="tips1 circle1" style="margin-top: 17px">
                  </div>
                  <span
                      style="font-size: 12px; margin-top: 16px;">如果您想拥有您企业专属的定制化版GPT油气知识，请联系我们</span>
                  <div style="text-align: right;">
                    <el-button
                        size="mini"
                        style="background-color: #bd0022;border: none;margin:-3px 5px; margin-top: 10px; margin-bottom: 10px;"
                        type="primary"
                        @click="getMemberById()"
                    >
                      联系方式
                    </el-button>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <el-dialog
                ref="myDialog1"
                title="联系方式"
                :visible.sync="centerDialogVisible"
                width="300px"
                class="connectForm"
                :before-close="handleClose"
                :append-to-body='true'
                top="40vh"
            >
              <span style="margin:5px">联系人：{{ contact.data.name }}</span><br><br>
              <span style="margin:5px">电话：{{ contact.data.phone }}</span><br><br>
              <span style="margin:5px">邮箱：{{ contact.data.email }}</span>
              <span slot="footer" class="dialog-footer"></span>
            </el-dialog> -->
          </div>
          <!-- <div class="box" v-if="languageSelect === 'en'">
            <div id="content" class="content" ref="content">
              <div v-for="(item, index) in infoEn" :key="index">
                <div class="info_r info_default" v-if="item.type === 'leftinfo'">
                  <span class="circle circle_r">
                    <img src="@/assets/ai.png" alt="" />
                  </span>
                  <div class="con_r con_text">
                    <div>{{ item.answer }}</div>
                    <div v-for="(item2, index) in item.question" :key="index">
                      <div class="con_que">
                        <div class="czkj-question-msg">
                          {{ item2.index }}
                          {{ item2.content }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="time_r">{{ item.time }}</div>
                </div>
                <div class="info_l" v-if="item.type === 'rightinfo'">
                  <span class="circle circle_l">
                    <img
                      :src="item.athor.avator"
                      alt="User Avatar"
                      style="border-radius: 45px"
                    />
                  </span>
                  <div class="con_l con_text">{{ item.content }}</div>
                  <div class="time_l">{{ item.time }}</div>
                </div>
              </div>
            </div>
            <div class="setproblem">
              <textarea
                placeholder="Please enter the question"
                style=""
                :disabled="isSendingMessage"
                id="text"
                :rows="1"
                v-model="customerTextEn"
                @keyup.enter="sentMsgEn()"
              ></textarea>
              <el-button @click="sentMsgEn()" class="buttonsend">
                <span style="vertical-align: 4px">Send</span>
              </el-button>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div v-if="isMobile === true" class="AiPop1" v-click-outside="handleBlur">
        <div class="buttonPop" @click="AiPopShow = !AiPopShow">
          <img src="@/assets/ai.png" />
        </div>
        <div class="pop" v-if="AiPopShow">
          <div class="box" v-if="languageSelect === 'zh'">
            <div id="content" class="content" ref="content">
              <div v-for="(item, index) in info" :key="index">
                <div class="info_r info_default" v-if="item.type == 'leftinfo'">
                  <div class="AiHeader">
                    <div style="display: flex">
                      <span class="circle circle_r">
                        <img src="@/assets/ai.png" alt="" />
                      </span>
                      <p style="margin-left: 10px">文档助手</p>
                    </div>
                    <div class="close" @click="close()"></div>
                  </div>
                  <p class="AiTitle">付费权益及企业专属定制化服务：</p> -->
                  <!-- <ul class="AiContent">
                    <li>
                      <p>
                        AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数，<span
                          class="AiTitle1">立即开通</span
                        >
                      </p>
                    </li>
                    <li>
                      <p>
                        AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数，<span
                           class="AiTitle1">立即开通</span
                        >
                      </p>
                    </li>
                  </ul> -->
  
                  <!-- <div style="height: 400px">
                    <div class="tips2">
                      <div style="display: flex">
                        <div class="circle1" style="margin-top: 9px"></div>
                        <span style="font-size: 12px; margin-top: 8px"
                          >AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数
                          <span
                            style="
                              color: blue;
                              border: none;
                              margin: -3px 5px;
                              margin-top: 2px;
                            "
                            @click="loginStart()"
                            >立即开通</span
                          ></span
                        >
                        <div style="text-align: right"></div>
                      </div>
                    </div>
                    <div class="tips2">
                      <div style="display: flex">
                        <div class="tips2 circle1" style="margin-top: 17px"></div>
                        <span style="font-size: 12px; margin-top: 16px"
                          >如果您想拥有您企业专属的定制化版GPT油气知识，请联系我们<span
                            style="
                              color: blue;
                              border: none;
                              margin: -3px 5px;
                              margin-top: 2px;
                            "
                            @click="getMemberById()"
                            >联系方式</span
                          ></span
                        >
                        <div style="text-align: right"> -->
                          <!-- <el-button
                        size="mini"
                        style="background-color: #bd0022;border: none;margin:-3px 5px; margin-top: 10px; margin-bottom: 10px;"
                        type="primary"
                        @click="getMemberById()"
                    >
                      联系方式
                    </el-button> -->
                        <!-- </div>
                      </div>
                    </div>
                  </div>
                  <el-dialog
                    ref="myDialog1"
                    title="联系方式"
                    :visible.sync="centerDialogVisible"
                    width="300px"
                    class="connectForm"
                    :before-close="handleClose"
                    :append-to-body="true"
                    top="40vh"
                  >
                    <span style="margin: 5px"
                      >联系人：{{ contact.data.name }}</span
                    ><br /><br />
                    <span style="margin: 5px">电话：{{ contact.data.phone }}</span
                    ><br /><br />
                    <span style="margin: 5px"
                      >邮箱：{{ contact.data.email }}</span
                    >
                    <span slot="footer" class="dialog-footer"></span>
                  </el-dialog>
                  <div class="setproblem" id="exceptionElementId">
                    <textarea
                      placeholder=""
                      style=""
                      :disabled="isSendingMessage"
                      id="text"
                      :rows="1"
                      v-model="customerText"
                      @keydown.enter="sentMsg()"
                    >
                    </textarea>
                    <div class="sendTitle">
                      <p>
                        <span style="color: blue" @click="login">登录</span>后，可向我发送问题
                      </p>
                    </div>
                    <div class="send" @click="sentMsg()">
                      <div class="sendBox">
                      <p></p>
                    </div>
                    </div>          
                  </div> -->
  
                  <!-- <div class="con_r con_text">
                    <div>{{ item.answer }}</div>
                    <div v-for="(item2, index) in item.question" :key="index">
                      <div
                          class="con_que"
                          @click="clickRobot(item2.content, item2.id)"
                      >
                        <div class="czkj-question-msg">
                          {{ item2.index }}
                          {{ item2.content }}
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="time_r">{{ item.time }}</div> -->
                <!-- </div>
                <div class="info_l" v-if="item.type == 'rightinfo'">
                  <span class="circle circle_l">
                    <img
                      :src="item.athor.avator"
                      alt="User Avatar"
                      style="border-radius: 45px"
                    />
                  </span>
                  <div class="con_l con_text">{{ item.content }}</div>
                  <div class="time_l">{{ item.time }}</div>
                </div>
              </div>
            </div>
            <div>
              <div v-if="this.user.userId"> -->
                <!-- 检查 countSum 和 countSum.data 是否存在 -->
                <!-- <span>您共拥有</span>
                <span style="color: red">
                  {{ countSum.data.freeNum + countSum.data.payNum }}
                </span>
                <span>条对话次数，已消耗</span>
                <span style="color: red">
                  {{ countSum.data.freeUse + countSum.data.payUse }}
                </span>
                <span>条对话次数。</span>
              </div>
            </div> -->
            <!-- <div class="setproblem">
            <textarea
                placeholder="请输入需要咨询的问题"
                style=""
                :disabled="isSendingMessage"
                id="text"
                :rows="1"
                v-model="customerText"
                @keydown.enter="sentMsg()"
            ></textarea>
              <el-button @click="sentMsg()" class="buttonsend">
                <span style="vertical-align: 4px">发送</span>
              </el-button>
  
              <el-dialog
                  ref="myDialog"
                  title="温馨提示"
                  :visible.sync="kindtips"
                  width="450px"
                  :append-to-body='true'
                  class="connectForm"
                  :before-close="handleClose"
                  top="40vh"
              >
                <span style="margin:5px;">您的免费对话次数已用尽，成为付费用户拥有更多对话次数~</span><br><br>
                <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog2" size="mini">取消</el-button>
                    <el-button
                        size="mini"
                        style="background-color: #bd0022; border: none; margin: -3px 5px"
                        type="primary"
                        @click="loginStart()"
                    >
                      立即开通
                    </el-button>
                </span>
              </el-dialog>
            </div>
            <div>
              <div class="tips1">
                <div style="display: flex">
                  <div class="circle1" style="margin-top: 9px">
                  </div>
                  <span
                      style="font-size: 12px; margin-top: 8px;">AI资讯对话属于付费权益，成为付费用户可拥有更多对话次数</span>
                  <div style="text-align: right;">
                    <el-button
                        size="mini"
                        style="background-color: #bd0022;border: none;margin:-3px 5px; margin-top: 2px;"
                        type="primary"
                        @click="loginStart()"
  
                    >
                      立即开通
                    </el-button>
                  </div>
  
                </div>
              </div>
              <div class="tips1">
                <div style="display: flex">
                  <div class="tips1 circle1" style="margin-top: 17px">
                  </div>
                  <span
                      style="font-size: 12px; margin-top: 16px;">如果您想拥有您企业专属的定制化版GPT油气知识，请联系我们</span>
                  <div style="text-align: right;">
                    <el-button
                        size="mini"
                        style="background-color: #bd0022;border: none;margin:-3px 5px; margin-top: 10px; margin-bottom: 10px;"
                        type="primary"
                        @click="getMemberById()"
                    >
                      联系方式
                    </el-button>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <el-dialog
                ref="myDialog1"
                title="联系方式"
                :visible.sync="centerDialogVisible"
                width="300px"
                class="connectForm"
                :before-close="handleClose"
                :append-to-body='true'
                top="40vh"
            >
              <span style="margin:5px">联系人：{{ contact.data.name }}</span><br><br>
              <span style="margin:5px">电话：{{ contact.data.phone }}</span><br><br>
              <span style="margin:5px">邮箱：{{ contact.data.email }}</span>
              <span slot="footer" class="dialog-footer"></span>
            </el-dialog> -->
          <!-- </div>
          <div class="box" v-if="languageSelect === 'en'">
            <div id="content" class="content" ref="content">
              <div v-for="(item, index) in infoEn" :key="index">
                <div class="info_r info_default" v-if="item.type === 'leftinfo'">
                  <span class="circle circle_r">
                    <img src="@/assets/ai.png" alt="" />
                  </span>
                  <div class="con_r con_text">
                    <div>{{ item.answer }}</div>
                    <div v-for="(item2, index) in item.question" :key="index">
                      <div class="con_que">
                        <div class="czkj-question-msg">
                          {{ item2.index }}
                          {{ item2.content }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="time_r">{{ item.time }}</div>
                </div>
                <div class="info_l" v-if="item.type === 'rightinfo'">
                  <span class="circle circle_l">
                    <img
                      :src="item.athor.avator"
                      alt="User Avatar"
                      style="border-radius: 45px"
                    />
                  </span>
                  <div class="con_l con_text">{{ item.content }}</div>
                  <div class="time_l">{{ item.time }}</div>
                </div>
              </div>
            </div>
            <div class="setproblem">
              <textarea
                placeholder="Please enter the question"
                style=""
                :disabled="isSendingMessage"
                id="text"
                :rows="1"
                v-model="customerTextEn"
                @keyup.enter="sentMsgEn()"
              ></textarea>
              <el-button @click="sentMsgEn()" class="buttonsend">
                <span style="vertical-align: 4px">Send</span>
              </el-button>
            </div>
          </div>
        </div> -->
        <!-- <div @click="AiPopShow = !AiPopShow">
          <div class="buttonPop">
            <img src="@/assets/ai.png" />
          </div>
          <div class="buttonTitle">GPT</div>
        </div>
  
        <div class="pop" v-if="AiPopShow">
          <div class="box" v-if="languageSelect === 'zh'">
            <div id="content" class="content" ref="content">
              <div v-for="(item, index) in info" :key="index">
                <div class="info_r info_default" v-if="item.type == 'leftinfo'">
                  <span class="circle circle_r">
                    <img src="@/assets/ai.png" alt="" />
                  </span>
                  <div class="con_r con_text">
                    <div>{{ item.answer }}</div>
                    <div v-for="(item2, index) in item.question" :key="index">
                      <div
                        class="con_que"
                        @click="clickRobot(item2.content, item2.id)"
                      >
                        <div class="czkj-question-msg">
                          {{ item2.index }}
                          {{ item2.content }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="time_r">{{ item.time }}</div>
                </div>
                <div class="info_l" v-if="item.type == 'rightinfo'">
                  <span class="circle circle_l">
                    <img
                      :src="item.athor.avator"
                      alt="User Avatar"
                      style="border-radius: 45px"
                    />
                  </span>
                  <div class="con_l con_text">{{ item.content }}</div>
                  <div class="time_l">{{ item.time }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="languageSelect === 'zh' && this.user.userId"
              style="font-size: 10px"
            >
              <span>您共拥有</span>
              <span style="color: red">
                {{
                  (countSum?.data?.freeNum || 0) + (countSum?.data?.payNum || 0)
                }}
              </span>
              <span>条对话次数，已消耗</span>
              <span style="color: red">
                {{
                  (countSum?.data?.freeUse || 0) + (countSum?.data?.payUse || 0)
                }}
              </span>
              <span>条对话次数。</span>
            </div>
            <div v-if="languageSelect === 'en' && this.user.userId">
              <span>You have</span>
              <span style="color: red">{{
                (countSum?.data?.freeNum || 0) + (countSum?.data?.payNum || 0)
              }}</span>
              <span>dialogue times, already used</span>
              <span style="color: red">{{
                (countSum?.data?.freeUse || 0) + (countSum?.data?.payUse || 0)
              }}</span>
              <span>times.</span>
            </div>
            <div class="setproblem">
              <textarea
                placeholder="请输入需要咨询的问题"
                style=""
                :disabled="isSendingMessage"
                id="text"
                :rows="1"
                v-model="customerText"
                @keyup.enter="sentMsg()"
              ></textarea>
              <el-button @click="sentMsg()" class="buttonsend">
                <span style="vertical-align: 4px">发送</span>
              </el-button>
            </div>
          </div>
          <div class="box" v-if="languageSelect === 'en'">
            <div id="content" class="content" ref="content">
              <div v-for="(item, index) in infoEn" :key="index">
                <div class="info_r info_default" v-if="item.type === 'leftinfo'">
                  <span class="circle circle_r">
                    <img src="@/assets/ai.png" alt="" />
                  </span>
                  <div class="con_r con_text">
                    <div>{{ item.answer }}</div>
                    <div v-for="(item2, index) in item.question" :key="index">
                      <div class="con_que">
                        <div class="czkj-question-msg">
                          {{ item2.index }}
                          {{ item2.content }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="time_r">{{ item.time }}</div>
                </div>
                <div class="info_l" v-if="item.type === 'rightinfo'">
                  <span class="circle circle_l">
                    <img
                      :src="item.athor.avator"
                      alt="User Avatar"
                      style="border-radius: 45px"
                    />
                  </span>
                  <div class="con_l con_text">{{ item.content }}</div>
                  <div class="time_l">{{ item.time }}</div>
                </div>
              </div>
            </div>
            <div class="setproblem">
              <textarea
                placeholder="Please enter the question"
                style=""
                :disabled="isSendingMessage"
                id="text"
                :rows="1"
                v-model="customerTextEn"
                @keyup.enter="sentMsgEn()"
              ></textarea>
              <el-button @click="sentMsgEn()" class="buttonsend">
                <span style="vertical-align: 4px">Send</span>
              </el-button>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
      <LoginTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
      ></LoginTip>
    </div>
  </template>
  <script>
  // 自定义指令，用于处理点击外部区域的事件
  import LoginTip from "@/components/LoginTip.vue";
  import { getAuthorize } from "@/api/user";
  import { mapState } from 'vuex'
  import mixins from "@/utils/mixins.js";
  
  const clickOutside = {
    bind(el, binding) {
      // 在元素上绑定一个点击事件监听器
      el.clickOutsideEvent = function (event) {
        // 检查点击事件是否发生在元素的内部
        if (!(el === event.target || el.contains(event.target))) {
          // 如果点击事件发生在元素的外部，则触发指令绑定的方法,将点击的event数据传过去
          binding.value(event);
        }
      };
      // 在文档上添加点击事件监听器
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
      // 在元素上解除点击事件监听器
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };
  export default {
    components: { LoginTip },
    mixins: [mixins],
    directives: {
      "click-outside": clickOutside, // 注册自定义指令
    },
    computed: {
      ...mapState({
        'access_token': 'access_token', 
        'userInfo': 'user'
      })
    },
    props: {
      // eslint-disable-next-line no-undef
      news_id: [Number, String],
    },
    name: "AiPop",
    data() {
      return {
        memberId: 2,
        contact: { data: {} },
        token: localStorage.getItem("access_token"),
        countSum: { data: { freeNum: "", freeUse: "", payNum: "", payUse: "" } }, // 模拟的初始数据
        dialogVisible: false,
        isMobile: false,
        languageSelect: "",
        AiPopShow: true,
        customerText: "",
        customerTextEn: "",
        question: "",
        centerDialogVisible: false,
        kindtips: false,
        user: {
          userId: "",
          name: "",
        },
        info: [
          // {
          //   type: "leftinfo",
          //   time: this.getTodayTime(),
          //   name: "robot",
          //   answer: "我是问答小助手，请问您有什么问题，我会尽力帮您解答。",
          //   answerEn:
          //     "I am a question and answer assistant. If you have any questions, I will try my best to help you answer them.",
          //   question: [],
          // },
        ],
        infoEn: [
          // {
          //   type: "leftinfo",
          //   time: this.getTodayTime(),
          //   name: "robot",
          //   answer:
          //     "I am a question and answer assistant. If you have any questions, I will try my best to help you answer them.",
          //   question: [],
          // },
        ],
        count: 0,
        isSendingMessage: false,
        timer: "",
        uniqueId: "",
        userId: localStorage.getItem("userId"),
  
  
        hasPlaceholder: true
      };
    },
    created() {
      if (localStorage.getItem("language")) {
        this.languageSelect = localStorage.getItem("language");
      } else {
        this.languageSelect = "zh";
      }
    },
    methods: {
      handleClose(){
        this.centerDialogVisible = false
      },
      login() {
        let href = window.location.href.split("&code")[0];
        getAuthorize(href).then((res) => {
          window.location.href = res.data.url;
        });
      },
      hidePlaceholder() {
        if (this.userInfo.userId) {
          this.hasPlaceholder = false;
          this.$refs.inputArea.focus();
        }
      },
      blurHandle() {
        if (this.$refs.inputArea.value) {
          this.hasPlaceholder = false;
        } else {
          this.hasPlaceholder = true;
        }
      },
      // 在printAnswerCharacterByCharacter中逐字输出答案
      Abstract(info) {
        this.customerText = info;
        this.sentMsg();
      },
      //点击其它区域
      handleBlur(event) {
        // console.log(event)
        const exceptionElement = document.getElementById("exceptionElementId"); // 获取例外元素的引用
        const popupElement1 = this.$refs.myDialog
          ? this.$refs.myDialog.$el
          : null; // 获取第一个弹窗元素的引用
        const popupElement2 = this.$refs.myDialog1
          ? this.$refs.myDialog1.$el
          : null; // 获取第二个弹窗元素的引用
          // console.log(exceptionElement, exceptionElement.contains(event.target))
          // exceptionElement.contains(event.target)
        if (exceptionElement && exceptionElement.contains(event.target)) {
          console.log("Clicked inside the exception element.");
        } else {
          if (popupElement1 && popupElement1.contains(event.target)) {
            // 如果点击事件发生在第一个弹窗内部，不执行任何操作，保持弹窗可见状态
          } else if (popupElement2 && popupElement2.contains(event.target)) {
            // 如果点击事件发生在第二个弹窗内部，不执行任何操作，保持弹窗可见状态
          } else {
            this.AiPopShow = false;
          }
        }
      },
      close(){
        this.AiPopShow = false;
      },
      loginStart() {
        if (this.userInfo.userId) {
          this.$router.push("/member");
        } else {
          this.dialogVisible = true;
        }
      },
      hideDialog() {
        this.dialogVisible = false;
      },
      
      
  
      closeDialog2() {
        this.kindtips = false; // 设置为 false，触发关闭
        this.AiPopShow = true;
      },
      //问答次数查询
      getQuestionCount() {
        this.$axios
          .post(
            "/api/user/getNum",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            // 处理响应数据
            this.countSum = response.data;
          })
          .catch((error) => {
            // 处理错误
            console.error("Error fetching member data:", error);
            // 这里可以处理错误信息
          });
      },
      //问答次数修改
      updateQaNum() {
        this.$axios
          .post(
            "/api/user/updateNum",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            // 处理响应数据
            this.countSum = response.data;
          })
          .catch((error) => {
            // 处理错误
            console.error("Error fetching member data:", error);
            // 这里可以处理错误信息
          });
      },
      getTodayTime() {
        // 获取当前时间
        var day = new Date();
        let seconds = day.getSeconds();
        if (seconds < 10) {
          seconds = "0" + seconds;
        } else {
          // eslint-disable-next-line no-self-assign
          seconds = seconds;
        }
        let minutes = day.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        } else {
          // eslint-disable-next-line no-self-assign
          minutes = minutes;
        }
        let time =
          day.getFullYear() +
          "-" +
          (day.getMonth() + 1) +
          "-" +
          day.getDate() +
          " " +
          day.getHours() +
          ":" +
          minutes +
          ":" +
          seconds;
        return time;
      },
      sentMsg() {
        // if (
        //   this.user.userId &&
        //   this.user.userId !== "" &&
        //   this.user.userId !== null &&
        //   this.user.userId !== undefined &&
        //   this.user.userId !== "null" &&
        //   this.user.userId !== "undefined"
        // ) {
          // if (!this.$store.state.access_token) {
          //   let that = this;
          //   that.$emit("change", true);
          //   return false;
          // }
          // if (this.countSum.data.freeNum + this.countSum.data.payNum <= 0) {
          //   this.kindtips = true;
          // } else {
            // 清除之前的定时器
            if (this.timer) {
              clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
              this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
              this.messages = [];
              this.count = 0;
              this.isSendingMessage = false;
              this.historylist();
              this.$notify.warning({
                title: "提示",
                message: "当前超出30min未进行对话，请刷新重试",
                showClose: true,
              });
            }, 1800000); // 等于 60,000*30 毫秒
  
            // 禁用发送按钮
            this.isSendingMessage = true;
            this.count++;
            if (this.uniqueId === "") {
              this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
            }
            console.log("uniqueId" + this.uniqueId);
  
            if (this.customerText) {
              // 问题
              this.question = this.customerText;
              this.info.push({
                type: "rightinfo",
                time: this.getTodayTime(),
                name: "robot",
                // athor: {
                //   avator: JSON.parse(localStorage.getItem("userInfo")).avatar,
                // },
                content: this.customerText,
              });
  
              this.customerText = "";
              
              // console.log(this.customerText)
  
              // 先添加“稍后再试”消息
              this.info.push({
                id: "",
                type: "leftinfo",
                time: this.getTodayTime(),
                name: "robot",
                answer: "正在生成，请稍候...",
              });
  
              if (this.count === 21) {
                this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
                this.info = [];
                this.count = 0;
                this.isSendingMessage = false;
                this.historylist();
                this.$notify.warning({
                  title: "提示",
                  message:
                    "当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。",
                  showClose: false,
                });
              } else {
                // this.customerText = "";
                var self = this;
                setTimeout(() => {
                  var vm = this; // 保存this
  
                  var params = this.news_id ? {
                    question: this.question,
                    user_id: '1', //this.news_id.toString(),
                  } : {
                    question: this.question,
                    round_id: this.uniqueId,
                    userId: '1', //,this.user.userId.toString(),
                  }
                  var url = this.news_id ? '/api/qa/get_content' : '/api/qa/get_answer'
  
                  vm.$axios
                    .post(
                      url,
                      params,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization:
                            "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2Vy_xxxxx",
                        },
                      }
                    )
                    .then( response => {
                      const answer = self.news_id ? response.data.restored_content : response.data.answer;
                      if(!answer) return
                      // self.updateQaNum();
                      // 清除"正在生成"消息
                      const index = vm.info.findIndex(
                        (message) => message.id === "" && message.name === "robot"
                      );
                      if (index !== -1) {
                        vm.info.splice(index, 1); // 从数组中删除该消息
                      }
                      const answerMessage = {
                        id: vm.getTodayTime(),
                        type: "leftinfo",
                        time: vm.getTodayTime(),
                        name: "robot",
                        answer: "", // 初始留空
                      };
  
                      vm.info.push(answerMessage); // 添加初始消息
  
                      let indexcount = 0;
                      const printInterval = setInterval(function () {
                        if (indexcount < answer.length) {
                          answerMessage.answer += answer.charAt(indexcount); // 逐字添加到答案中
                          indexcount++;
  
                          // 更新已添加的消息，实现逐字更新
                          vm.$set(
                            vm.info,
                            vm.info.indexOf(answerMessage),
                            answerMessage
                          );
                          vm.$nextTick(() => {
                            // 在下一个tick时滚动到底部
                            const container = document.getElementById("content");
                            container.scrollTop = container.scrollHeight;
                          });
                        } else {
                          clearInterval(printInterval); // 停止逐字打印
                          vm.isSendingMessage = false;
                          vm.$nextTick(function(){
                            // 在下一个tick时滚动到底部
                            const container = document.getElementById("content");
                            container.scrollTop = container.scrollHeight;
                          });
                        }
                      }, 100); // 每100毫秒打印一个字符，可以根据需要调整
                      this.$nextTick(() => {
                        // 在下一个tick时滚动到底部
                        const container = document.getElementById("content");
                        container.scrollTop = container.scrollHeight;
                      });
                    });
                }, 2000); // 2000毫秒（2秒）
  
                this.$nextTick(() => {
                  // 在下一个tick时滚动到底部
                  const container = document.getElementById("content");
                  container.scrollTop = container.scrollHeight;
                });
              }
            } else {
              this.$notify.warning({
                title: "提示",
                message: "请输入您的问题",
                showClose: false,
              });
            }
            this.isSendingMessage = false;
            this.$nextTick(() => {
              // 在下一个tick时滚动到底部
              const container = this.$refs.content;
              container.scrollTop = container.scrollHeight;
            });
          // }
        // } else {
        //   this.dialogVisible = true;
        // }
      },
      //联系人信息查询
      getMemberById() {
        this.$axios
          .get("/api/member/getGpt/" + this.memberId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((response) => {
            // 处理响应数据
            this.contact = response.data;
            // 这里可以根据返回的数据进行相应的操作
          })
          .catch((error) => {
            // 处理错误
            console.error("Error fetching member data:", error);
            // 这里可以处理错误信息
          });
  
        this.centerDialogVisible = true; // 这行代码确保即使请求出错也能显示对话框，如果这行代码是为了显示对话框，请放在请求结束后
      },
      sentMsgEn() {
        // if (
        //   this.user.userId &&
        //   this.user.userId !== "" &&
        //   this.user.userId !== null &&
        //   this.user.userId !== undefined &&
        //   this.user.userId !== "null" &&
        //   this.user.userId !== "undefined"
        // ) {
          // if (!this.$store.state.access_token) {
          //   let that = this;
          //   that.$emit("change", true);
          //   return false;
          // }
          // if (this.countSum.data.freeNum + this.countSum.data.payNum <= 0) {
          //   this.kindtips = true;
          // } else {
            // 清除之前的定时器
            if (this.timer) {
              clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
              this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
              this.messages = [];
              this.count = 0;
              this.isSendingMessage = false;
              this.historylist();
              this.$notify.warning({
                title: "提示",
                message: "当前超出30min未进行对话，请刷新重试",
                showClose: true,
              });
            }, 1800000); // 等于 60,000*30 毫秒
  
            // 禁用发送按钮
            this.isSendingMessage = true;
            this.count++;
            if (this.uniqueId === "") {
              this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
            }
            console.log("uniqueId" + this.uniqueId);
  
            if (this.customerText) {
              // 问题
              this.question = this.customerText;
              this.info.push({
                type: "rightinfo",
                time: this.getTodayTime(),
                name: "robot",
                // athor: {
                //   avator: JSON.parse(localStorage.getItem("userInfo")).avatar,
                // },
                content: this.customerText,
              });
              this.customerText = "";
  
              // 先添加“稍后再试”消息
              this.info.push({
                id: "",
                type: "leftinfo",
                time: this.getTodayTime(),
                name: "robot",
                answer: "Generating, please wait...",
              });
  
              if (this.count === 21) {
                this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
                this.info = [];
                this.count = 0;
                this.isSendingMessage = false;
                this.historylist();
                this.$notify.warning({
                  title: "Tips",
                  message:
                    "The current round of dialogue is beyond the limits of DRE's ability, we will start a new round of dialogue.",
                  showClose: false,
                });
              } else {
                this.customerText = "";
                var self = this;
                setTimeout(() => {
                  var vm = this; // 保存this
  
                  var params = this.news_id ? {
                    question: this.question,
                    user_id: '1', //this.news_id.toString(),
                  } : {
                    question: this.question,
                    round_id: this.uniqueId,
                    userId: '1', //this.user.userId.toString(),
                  }
                  var url = this.news_id ? '/api/qa/get_content' : '/api/qa/get_answerEn'
  
                  this.$axios
                    .post(
                      url,
                      params,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization:
                            "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2Vy_xxxxx",
                        },
                      }
                    )
                    .then(response => {
                      // const answer = response.data.answer;
                      const answer = self.news_id ? response.data.answerTextEn : response.data.answer;
                      if(!answer) return
  
                      // self.updateQaNum();
                      // 清除"正在生成"消息
                      const index = vm.info.findIndex(
                        (message) => message.id === "" && message.name === "robot"
                      );
                      if (index !== -1) {
                        vm.info.splice(index, 1); // 从数组中删除该消息
                      }
                      const answerMessage = {
                        id: vm.getTodayTime(),
                        type: "leftinfo",
                        time: vm.getTodayTime(),
                        name: "robot",
                        answer: "", // 初始留空
                      };
  
                      vm.info.push(answerMessage); // 添加初始消息
  
                      let indexcount = 0;
                      const printInterval = setInterval(function () {
                        if (indexcount < answer.length) {
                          answerMessage.answer += answer.charAt(indexcount); // 逐字添加到答案中
                          indexcount++;
  
                          // 更新已添加的消息，实现逐字更新
                          vm.$set(
                            vm.info,
                            vm.infoEn.indexOf(answerMessage),
                            answerMessage
                          );
                          vm.$nextTick(() => {
                            // 在下一个tick时滚动到底部
                            const container = document.getElementById("content");
                            container.scrollTop = container.scrollHeight;
                          });
                        } else {
                          clearInterval(printInterval); // 停止逐字打印
                          vm.isSendingMessage = false;
                          vm.$nextTick(() => {
                            // 在下一个tick时滚动到底部
                            const container = document.getElementById("content");
                            container.scrollTop = container.scrollHeight;
                          });
                        }
                      }, 100); // 每100毫秒打印一个字符，可以根据需要调整
                      
                      this.$nextTick(() => {
                        // 在下一个tick时滚动到底部
                        const container = document.getElementById("content");
                        container.scrollTop = container.scrollHeight;
                      });
                    });
                  // this.updateQaNum();
                }, 2000); // 2000毫秒（2秒）
                this.isSendingMessage = false;
                this.$nextTick(() => {
                  // 在下一个tick时滚动到底部
                  const container = document.getElementById("content");
                  container.scrollTop = container.scrollHeight;
                });
              }
            } else {
              this.$notify.warning({
                title: "Tips",
                message: "Please enter your question",
                showClose: false,
              });
            }
  
            this.$nextTick(() => {
              // 在下一个tick时滚动到底部
              const container = document.getElementById("content");
              container.scrollTop = container.scrollHeight;
            });
        //   }
        // } else {
        //   this.dialogVisible = true;
        // }
      },
      // 在printAnswerCharacterByCharacter中逐字输出答案
    },
    mounted() {
      // Check if the device width is less than 768px (example for mobile)
      this.isMobile = window.matchMedia("(max-width: 767px)").matches;
      this.getQuestionCount();
    },
    watch: {
      "$store.state.user"(newValue) {
        this.user = newValue;
      },
      AiPopShow(newVal){
        if(newVal && this.isMobile){
          document.body.style.overflow = 'hidden';
        }else{
          document.body.style.overflow = 'initial';
        }
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  .AiPop {
    position: fixed;
    top: 30vh;
    right: calc(50vw - 820px - 140px);
    z-index: 30;
  
    .buttonPop {
      width: 104px;
      height: 100px;
      cursor: pointer;
    }
  
    .chat-box{
      background: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.6) 100%);
    }
    .chat-list{
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
  
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 4px;
        transition: all 2s;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--main-color);
        border-radius: 100px;
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
    
    .ai-tip{
      margin-bottom: 10px;
      padding: 4px 8px;
      span{
        color: var(--main-color);
        cursor: pointer;
      }
    }
    .user-ai-sum{
      font-size: 12px;
    }
    .abstract-chat{
      cursor: pointer;
    }
  
    .pop {
      position: fixed;
      top: 0;
      right: 0;
      width: 342px;
      height: 100%;
      // background: rgb(229, 247, 251);
      // background: url(../assets/aiBg.png) no-repeat center top;
      background: url(../assets/aiBg.png) no-repeat;
      background-size: cover;
      // padding-right: 20px;
      // padding-top: 82px;
      border-radius: 10px;
      overflow: hidden;
      font-size: 14px;
      z-index: 30;
      // margin-bottom: 10px;
      // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      .box {
        // width: 342px;
        // background: #fafbff;
        height: 100%;
        padding: 20px;
  
        .content{
          display: flex;
          flex-direction: column;
          height: 100%;
  
          .chat-type-area{
            margin-top: 10px;
            .setproblem{
              position: relative;
              background-image: linear-gradient(90deg, #1c77ff, #22af89);
              border-radius: 12px;
              box-shadow: 0 0 12px 0 rgba(0, 155, 109, .15);
              box-sizing: border-box;
              padding: 2px;
              textarea{
                padding: 8px 8px 4px;
                border: 0 none;
                height: 80px;
                line-height: 1.3;
                font-size: 14px;
                border-radius: 12px 12px 0 0;
                color: #222;
              }
              .sendTitle{
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                padding: 8px 10px;
                z-index: 10;
                span{
                  cursor: pointer;
                }
              }
              .send{
                position: static;
                background-color: #fff;
                border-radius: 0 0 12px 12px;
                padding: 6px 10px 4px;
                text-align: right;
                .sendBox{
                  display: inline-block;
                  cursor: pointer;
                }
              }
            }
          }
        }
  
        .chat-list{
          .circle_r {
            position: absolute;
            left: 0%;
          }
        }
        .circle {
          display: inline-block;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          // background-color: #eff1f3;
        }
  
        .con_text {
          color: #333;
          margin-bottom: 5px;
        }
  
        .con_que {
          color: #1c88ff;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
        }
  
        .info_r {
          position: relative;
          margin-bottom: 10px;
        }
  
        
  
        .pic_r {
          width: 17px;
          height: 17px;
          margin: 8px;
        }
  
        .con_r {
          display: inline-block;
          width: 85%;
          min-height: 55px;
          background-color: #ffffff;
          border-radius: 6px;
          padding: 10px;
          margin-left: 40px;
        }
  
        .time_r {
          margin-left: 45px;
          color: #999999;
          font-size: 12px;
        }
  
        .info_l {
          text-align: right;
          color: #000;
          color: #a9ea7a;
          margin-bottom: 10px;
          position: relative;
  
          .circle_l {
            position: absolute;
            right: 0;
          }
        }
  
        .pic_l {
          width: 13px;
          height: 17px;
          margin: 8px 10px;
        }
  
        .time_l {
          margin-right: 45px;
          color: #999999;
          font-size: 12px;
          // margin-top: 5px;
        }
  
        .con_l {
          width: auto;
          max-width: 220px;
          height: auto;
          min-height: 36px;
          background-color: #a9ea7a;
          border-radius: 6px;
          padding: 10px;
          text-align: left;
          color: #000;
          margin-right: 40px;
          display: inline-block;
          white-space: normal; /* 修改此处以支持换行 */
          overflow: hidden;
          vertical-align: top;
        }
  
        #question {
          cursor: pointer;
        }
  
        .setproblem {
          width: 100%;
          // height: 68px;
          // background-color: #ffffff;
          // margin-top: 20px;
  
          textarea {
            display: block;
            width: 100%;
            margin-bottom: 0px;
            color: #999999;
            padding: 0px 2px;
            box-sizing: border-box;
            // height: 64px;
            resize: none;
            outline: none;
            border-color: var(--main-color);
            border-radius: 5px;
            font-size: 12px;
            line-height: 80px;
            position: relative;
          }
  
          .buttonsend {
            background: var(--main-color);
            opacity: 1;
            font-size: 16px;
            color: #ffffff;
            position: absolute;
            right: 0%;
            bottom: 0;
            cursor: pointer;
            border: none;
            width: 68px;
            height: 34px;
            line-height: 34px;
            border-radius: 5px;
            padding: 0;
          }
        }
  
        .czkj-item-title {
          line-height: 25px;
          border-bottom: 1px solid #ccc;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
  
        .czkj-item-question {
          cursor: pointer;
          display: block;
          padding: 8px;
          position: relative;
          border-bottom: 1px dashed #ccc;
          line-height: 20px;
          min-height: 20px;
          overflow: hidden;
        }
  
        .czkj-question-msg {
          float: left;
          font-size: 14px;
          color: #3163c5;
        }
      }
    }
  }
  
  .AiPop1 {
    position: fixed;
    top: 60vh;
    right:20px;
    z-index: 40;
  
    //opacity: 0.4;
  
    .buttonPop {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  
    .buttonTitle {
      font-weight: bold;
      opacity: 0.7;
      font-size: 16px;
      z-index: 99999999999999999;
    }
  
    .pop {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: url(../assets/aiBg.png) no-repeat;
      background-size: 100% 100%;
      padding-right: 10px;
      padding-top: 5px;
      border-radius: 10px;
      overflow: hidden;
      // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      .box {
        // margin-top: 60px;
        // width: 200px;
        // background: #fafbff;
        height: 100%;
        padding: 10px;
  
        #content {
          // height: 150px;
          overflow-y: scroll;
          font-size: 14px;
          width: 99%;
          padding-right: 5px;
          overflow-x: hidden;
        }
  
        .circle {
          display: inline-block;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: #eff1f3;
        }
  
        .con_text {
          color: #333;
          margin-bottom: 2px;
        }
  
        .con_que {
          color: #1c88ff;
          height: 10px;
          line-height: 30px;
          cursor: pointer;
        }
  
        .info_r {
          position: relative;
        }
  
        .circle_r {
          // position: absolute;
          // left: 0%;
        }
  
        .pic_r {
          width: 17px;
          height: 17px;
          margin: 8px;
        }
  
        .con_r {
          display: inline-block;
          width: 88%;
          min-height: 35px;
          background-color: #ffffff;
          border-radius: 6px;
          padding: 2px;
          margin-left: 25px;
        }
  
        .time_r {
          margin-left: 25px;
          color: #999999;
          font-size: 10px;
        }
  
        .info_l {
          text-align: right;
          color: #000;
          margin-top: 10px;
          position: relative;
  
          .circle_l {
            position: absolute;
            right: 0;
          }
        }
  
        .pic_l {
          width: 13px;
          height: 17px;
          margin: 8px 10px;
        }
  
        .time_l {
          margin-right: 25px;
          color: #999999;
          font-size: 10px;
          // margin-top: 5px;
        }
  
        .con_l {
          width: auto;
          max-width: 88%;
          height: auto;
          min-height: 22px;
          background-color: #a9ea7a;
          border-radius: 6px;
          padding: 10px;
          text-align: left;
          color: #000;
          margin-right: 25px;
          display: inline-block;
          white-space: normal; /* 修改此处以支持换行 */
          overflow: hidden;
        }
  
        #question {
          cursor: pointer;
        }
  
        .setproblem {
          width: 100%;
          height: 68px;
          // background-color: #ffffff;
          position: relative;
          margin-top: 20px;
          display: flex;
          font-size: 10px;
  
          textarea {
            display: block;
            width: calc(100% - 0px);
            margin-bottom: 0px;
            color: #999999;
            padding: 5px 5px;
            box-sizing: border-box;
            height: 64px;
            resize: none;
            outline: none;
            border-color: var(--main-color);
            border-radius: 5px;
            font-size: 14px;
            line-height: 32px;
          }
  
          .buttonsend {
            background: var(--main-color);
            opacity: 1;
            font-size: 14px;
            color: #ffffff;
            position: absolute;
            right: 0;
            bottom: 0;
            cursor: pointer;
            border: none;
            width: 32px;
            height: 35px;
            align-items: center;
            line-height: 34px;
            border-radius: 5px;
            padding: 0;
          }
        }
  
        .czkj-item-title {
          line-height: 25px;
          border-bottom: 1px solid #ccc;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
  
        .czkj-item-question {
          cursor: pointer;
          display: block;
          padding: 8px;
          position: relative;
          border-bottom: 1px dashed #ccc;
          line-height: 20px;
          min-height: 20px;
          overflow: hidden;
        }
  
        .czkj-question-msg {
          float: left;
          font-size: 14px;
          color: #3163c5;
        }
      }
    }
  
    .tips1 {
      color: gray;
      padding: 5px 7vh 5px 5px;
      margin-top: 3px;
      margin-left: 10%;
      font-size: 14px;
      margin-bottom: 10px;
  
      .circle1 {
        width: 15px;
        height: 15px;
        margin: 2.5px;
        border-radius: 50%;
        border: gray 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: gray;
      }
    }
  }
  
  .AiPop1:after {
    right: 20px;
    transition: height 0.3s ease; /* 使用过渡效果平滑改变高度 */
  }
  
  .el-avatar {
    width: 100%;
    height: 100%;
  }
  
  .AiPop .pop .box #content::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: var(--main-color);
  }
  
  .AiPop .pop .box #content::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  .AiPop .pop .box #content::-webkit-scrollbar-track-piece {
    background-color: #fafbff;
  }
  
  @media screen and (max-width: 1570px) {
    .AiPop {
      right: 10px;
    }
  }
  
  // me
  .AiHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 56px;
  }
  .AiHeader-img{
    display: flex;
    align-items: center;
    p{
      margin-left: 10px;
    }
  }
  
  .AiTitle {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 10px 0;
    padding: 4px 8px ;
  }
  
  .close {
    // background-image: url(https://edu-wenku.bdimg.com/v1/pc/aigc/close-1713445304960.svg)  !important;
    // background-repeat: no-repeat;
    // background-position: 50%;
    // background-size: auto;
    // height: 22px;
    // top: 18px;
    // width: 22px;
    font-size: 28px;
    cursor: pointer;
  }
  
  .AiContent {
    list-style: none;
    li {
      margin: 10px 10px 10px 0;
      P {
        background-color: #fff;
        .AiTitle1 {
          color: blue;
        }
      }
    }
  }
  
  .send {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  
  .sendTitle{
    position: absolute;
    top: 20px;
    left: 10px;
  }
  
  .sendBox {
    background: linear-gradient(316deg, #21ab86 16.71%, #25bf94 116.53%);
    border-radius: 8px;
    height: 26px;
    position: relative;
    width: 36px;
    p {
      background: url('../assets/send.svg')
        no-repeat 50% 50%;
      background-size: cover;
      content: "";
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
    }
  }
  
  
  .tips2 {
    background: #fff;
      color: gray;
      // padding: 5px 7vh 5px 5px;
      margin-top: 3px;
      margin-left: 10%;
      font-size: 14px;
      margin-bottom: 10px;
  
      .circle1 {
        width: 15px;
        height: 15px;
        margin: 2.5px;
        border-radius: 50%;
        border: gray 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: gray;
      }
    }
  
  .m-aipop {
    position: fixed;
    top: 60vh;
    right: 18px;
    z-index: 40;
    .buttonPop {
      width: 38px;
      height: 38px;
      cursor: pointer;
    }
    .pop{
      width: 100%;
      border-radius: 0;
    }
  }
  </style>
  