<template>
    <div class="screenModel">
      <div class="foot">
        <div class="flex">
          <p>版权所有 © 安东石油技术（集团）有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 测试版</p>
        </div>
      </div>
  </div>

</template>


<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      model:false,
    }

  },

  methods: {},

  mounted() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
      console.log("Screen Width: ", screenWidth);
      console.log("Screen Height: ", screenHeight);

      if(screenWidth<=700){
        this.model=true
      }else{
        this.model=false
      }


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.screenModel{
  width:100%;
  height: fit-content;
  background-color: transparent;
  position: absolute;
  bottom: 8px;
  .foot {
    // padding-top: 15px;
    text-align: center;
    display: flex;
    //border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 0.5vw;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 0.5vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 0.5vw;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }

}
</style>
